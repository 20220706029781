<template>
  <div v-if="isAuthenticated">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success"><h3><i class="fi-mail"></i>&nbsp;&nbsp;&nbsp;Nachrichten-Verwaltung
      </h3></div>
    </div>
    <div class="grid-x grid-padding-x">

<!--
      <div class="cell">
        <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                  @md-selected="onSelect" style="overflow-x: hidden">
          <md-table-toolbar>
            <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
              <div class="cell large-2 medium-4 small-10">
                <p class="md-title ai-number">{{searched.length}}&nbsp;Benutzer</p>
                <p v-if="users.length - searched.length > 0" style="color: crimson; margin: 0; padding-left: 10px;">{{users.length - searched.length}} nicht sichtbar
                  <md-tooltip style="font-size: medium; height: auto; white-space: pre-wrap;" md-direction="bottom" md-delay="500">
                    <b>Warum nicht sichtbar?</b><br><br>
                    <span>Durch setzen von Filtern wird die Liste eingeschränkt.</span><br><br>
                    <span>Klicken Sie auf <md-button class="md-icon-button md-raised md-primary" style="background: cornflowerblue; margin-bottom: 10px">
                                  <md-icon style="color: white">select_all</md-icon>
                                </md-button> um alle Einträge zu sehen.</span>
                  </md-tooltip>
                </p>
                <p v-else style="color: seagreen; margin: 0; padding-left: 10px;">
                  Alle sichtbar
                </p>
                <br>
              </div>
              <div class="cell large-1 medium-2 small-2">
                <div v-if="sending">
                  <vue-simple-spinner></vue-simple-spinner>
                </div>
                <div v-else>
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <md-button class="md-icon-button md-raised md-primary" style="background: cornflowerblue" @click="onShowUsers">
                        <md-icon>select_all</md-icon>
                        <md-tooltip md-direction="bottom" style="font-size: medium;">
                          Alle <b>{{users.length}}</b> Benutzer <span v-if="organization.name != 'ai42'">des Trägers <b>{{organization.name}}</b></span> anzeigen
                        </md-tooltip>
                      </md-button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="availableFacilities && availableFacilities.length > 0" class="cell large-3 medium-6 small-12">
                <md-field>
                  <label>Berechtigt für Einrichtung</label>
                  <md-select v-model="selectedFacilityReferenceNumber" :disabled="sending || availableFacilities.length <= 1"
                             @md-selected="onSelectFacility">
                    <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber" :key="facility.id">
                      {{facility.name}}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div v-if="availableFacilities && availableFacilities.length > 0" class="cell large-3 medium-6 small-12">
                <md-field>
                  <label>Benutzer-Profil</label>

                  <md-select v-if="availableProfiles" v-model="selectedProfile" autocomplete="off" :disabled="sending"
                             @md-selected="onSelectProfile">
                    <md-option v-for="profile of availableProfiles" :value="profile" :key="profile">{{labels.rolesprofile[profile]}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell large-3 medium-6 small-12">
                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input placeholder="Suche Nachname ..." v-model="search" @input="searchOnTable"/>
                </md-field>
              </div>
            </div>
          </md-table-toolbar>

          <md-table-empty-state v-if="search"
                                md-label="Keinen Benutzer gefunden"
                                :md-description="`Keinen Benutzer mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff oder legen Sie einen neuen Benutzer an.`">
          </md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
            <md-table-cell v-if="isSage" md-label="Kundennummer" md-sort-by="customerNo" md-numeric>{{ item.customerNo }}
            </md-table-cell>
            <md-table-cell md-label="Vorname" md-sort-by="firstName">{{ item.firstName }}</md-table-cell>
            <md-table-cell md-label="Nachname" md-sort-by="lastName">{{ item.lastName }}</md-table-cell>
            <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
            <md-table-cell md-label="Berechtigte Einrichtungen" md-sort-by="allowedFacilitiesString"
                           v-html="item.allowedFacilitiesString"></md-table-cell>
            <md-table-cell md-label="Profil" md-sort-by="rolesProfileLabel">{{ item.rolesProfileLabel }}</md-table-cell>
            <md-table-cell md-label="Aktiv" md-sort-by="active"><p v-if="item.active">ja</p><p v-else>nein</p></md-table-cell>
            <md-table-cell md-label="Gesperrt" md-sort-by="locked">
              <p v-if="item.locked"><i class="fi-lock"></i></p>
            </md-table-cell>
            <md-table-cell md-label="Aktuelle Anmeldung" md-sort-by="loginTimeSortString">
              {{item.loginTimeSortString}}
              <md-tooltip style="font-size: medium; height: auto; width: 500px; white-space: pre-wrap;" md-direction="top">
                <span>Anmeldungsinformationen : <span style="color: lawngreen;">{{item.firstName}} {{item.lastName}}</span></span><br><br>
                <span>Aktuelle Anmeldung : <span style="color: lawngreen;">{{item.loginTimeString}}</span></span><br>
                <span>Letzte Anmeldung : <span style="color: lawngreen;">{{item.lastLoginTimeString}}</span></span><br>
                <span>Ungültige Anmeldeversuche : <span style="color: lawngreen;">{{item.invalidLoginAttempts}}</span></span>
              </md-tooltip>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
-->

    </div>
<!--
    <br>
    <div class="grid-x grid-padding-x">
      <div v-if="isSage" class="cell large-3 medium-6">
        <button @click="createUserDialog" class="button success expanded">
          <i class="fi-torso"></i>&nbsp;&nbsp;&nbsp;Benutzer anlegen
        </button>
      </div>
      <div v-if="isAdmin && !isSage" class="cell large-3 medium-6">
        <button @click="addUserDialog" class="button success expanded"><i class="fi-torso-female">
        </i>&nbsp;&nbsp;&nbsp;Benutzer hinzufügen
        </button>
      </div>
      <div v-if="isAdmin" class="cell large-3 medium-6">
        <button @click="editUserDialog" class="button expanded" :disabled="!selected">
          <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Benutzer bearbeiten
        </button>
      </div>
      <div v-if="isAdmin" class="cell large-3 medium-6">
        <button @click="deleteUserDialog" class="button alert expanded"
             :disabled="!selected || (selected && selected.id === user.id)">
          <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Benutzer löschen
        </button>
      </div>
      <div v-if="isAdmin" class="cell large-3 medium-6">
        <button @click="unlockUserDialog" class="button secondary expanded"
             :disabled="!selected || (selected && selected.locked === false)">
          <i class="fi-unlock"></i>&nbsp;&nbsp;&nbsp;Benutzer entsperren
        </button>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteUserDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Benutzer löschen</md-dialog-title>
        <div class="cell">
          <md-content>Soll der Benutzer <b>{{selected.username}}</b> wirklich gelöscht werden?</md-content>
        </div>
        <div class="cell text-center">
          <div v-if="isAdmin" @click="onDeleteUser" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            Benutzer jetzt löschen
          </div>
          <button class="button success" style="margin-left: 1rem;"
                  @click="showDeleteUserDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeleteUserDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showUnlockUserDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-unlock"></i>&nbsp;&nbsp;&nbsp;Benutzer entsperren</md-dialog-title>
        <div class="cell">
          <md-content>Soll die Sperre für Benutzer <b>{{selected.username}}</b> wirklich aufgehoben werden?</md-content>
        </div>
        <div class="cell text-center">
          <div v-if="isAdmin" @click="onUnlockUser" class="button alert"><i class="fi-unlock"></i>&nbsp;&nbsp;&nbsp;Ja,
            Benutzer jetzt entsperren
          </div>
          <button class="button success" style="margin-left: 1rem;"
                  @click="showUnlockUserDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showUnlockUserDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <EditUserDialog ref="editUserDialog" :showDialog="showEditUserDialog" :user="selectedUser" :mode="dialogMode"
                    :availableFacilities="availableFacilities"
                    @updateUserSuccess="updateUserSuccess"
                    @closeEditUserDialog="showEditUserDialog = false"></EditUserDialog>
-->
  </div>
</template>

<script>
  import Spinner from 'vue-simple-spinner'

  /*
    const toLower = text => {
      return text.toString().toLowerCase();
    };

    const searchByName = (items, term) => {
      if (term) {
        return items.filter(item => toLower(item.lastName).includes(toLower(term)));
      }

      return items;
    };

    const searchByFacility = (items, term) => {
      if (term) {
        return items.filter(item => {
          for (const facility of item.allowedFacilities) {
            if (facility === term) {
              return true;
            }
          }
          return false;
        });
      }

      return items;
    };

    const searchByProfile = (items, term) => {
      if (term) {
        return items.filter(item => item.rolesProfile === term);
      }

      return items;
    };
  */

  export default {
    name: 'Messages',
    components: {
      'vue-simple-spinner': Spinner
    },

    mounted() {
/*
      this.restoreUserSettings();
      this.reloadUsers();
*/
    },

    data() {
      return {
        sending: false,
        users: [],
        searched: [],
        search: null,
        selected: null,
        showDeleteUserDialog: false,
        showUnlockUserDialog: false,
        showEditUserDialog: false,
        success: false,
        lastUser: '',
        dialogMode: 'update',
        selectedUser: null,
        availableFacilities: [],
        availableFacilitiesMap: {},
        selectedFacilityReferenceNumber: null,
        selectedFacilityName: '',
        availableProfiles: [],
        selectedProfile: null,
      }
    },

    methods: {

 /*     onShowUsers() {
        this.selectedFacilityReferenceNumber = null;
        this.selectedProfile = null;
        this.search = null;
      },

      restoreUserSettings() {
        this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
      },

      reloadUsers() {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            if (this.isSage) {
              this.getAllFacilities();
            } else {
              if (this.organization.facilities) {
                this.availableFacilities.push({customerNo: '', name: 'Alle', referenceNumber: '*'});
                this.availableFacilitiesMap['*'] = 'Alle';
                for (let facility of this.organization.facilities) {
                  this.availableFacilities.push({customerNo: facility.customerNo, name: facility.name, referenceNumber: facility.referenceNumber});
                  this.availableFacilitiesMap[facility.referenceNumber] = facility.name;
                }
              }

              if (this.isAdmin) {
                this.getAllUsers();
              }
            }

            clearInterval(reloadIntervalId);
          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      getAllFacilities() {
        this.sending = true;
        FacilityService.getAllFacilities()
          .then(response => {
            this.availableFacilities.push({customerNo: '', name: 'Alle', referenceNumber: '*'});
            this.availableFacilitiesMap['*'] = 'Alle';
            for (let facility of response.data) {
              this.availableFacilities.push({customerNo: facility.customerNo, name: facility.name, referenceNumber: facility.referenceNumber});
              this.availableFacilitiesMap[facility.referenceNumber] = facility.name;
            }

            this.sending = false;
            this.getAllUsers();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Einrichtungen');
            this.sending = false;
          })
      },

      getAllUsers() {
        this.sending = true;
        UserService.getAllUsers()
          .then(response => {
            this.users = response.data;

            for (let i = 0; i < this.users.length; i++) {
              this.users[i].allowedFacilitiesString = '';
              if (this.users[i].allowedFacilities) {
                let all = false;
                for (let facility of this.users[i].allowedFacilities) {
                  if (facility === '*') {
                    all = true;
                    let j = 1;
                    for (let fa of this.availableFacilities) {
                      if (((fa.referenceNumber !== '*') && (fa.customerNo === this.users[i].customerNo)) || (this.users[i].sage)) {
                        this.users[i].allowedFacilitiesString += '(' + j++ + ') ' + this.availableFacilitiesMap[fa.referenceNumber] + '<br>';
                      }
                    }

                    if (j <= 1) {
                      this.users[i].allowedFacilitiesString += '<i>(Keine Einrichtungen)</i>';
                    } else {
                      this.users[i].allowedFacilitiesString = '<b>Alle :<br>' + this.users[i].allowedFacilitiesString + '</b>';
                    }
                  }
                }
                if (!all) {
                  let j = 1;
                  for (let facility of this.users[i].allowedFacilities) {
                    this.users[i].allowedFacilitiesString += '(' + j++ + ') ' + this.availableFacilitiesMap[facility] + '</br>';
                  }
                }
              }

              this.users[i].loginTimeString = '';
              this.users[i].loginTimeSortString = '';
              if (this.users[i].loginTime) {
                this.users[i].loginTimeString = Vue.moment(this.users[i].loginTime).format('dddd DD.MM.YYYY LTS') + ' Uhr';
                this.users[i].loginTimeSortString = Vue.moment(this.users[i].loginTime).format('DD.MM.YYYY LTS');
              }
              this.users[i].lastLoginTimeString = '';
              if (this.users[i].lastLoginTime) {
                this.users[i].lastLoginTimeString = Vue.moment(this.users[i].lastLoginTime).format('dddd DD.MM.YYYY LTS') + ' Uhr';
              }
            }

            this.searchOnTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Benutzerdaten');
            this.sending = false;
          })
      },

      getProfiles() {
        this.sending = true;
        UserService.getProfiles()
          .then(response => {
            this.availableProfiles = response.data;
            if (!this.isSage) {
              for (let i = 0; i < this.availableProfiles.length; i++) {
                if (this.availableProfiles[i] === 'sage') {
                  this.availableProfiles = this.availableProfiles.slice(0, i).concat(this.availableProfiles.slice(i + 1, this.availableProfiles.length));
                }
              }
            }
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Liste aller Nutzerprofile');
            this.sending = false;
          })
      },

      createUserDialog() {
        this.dialogMode = 'create';
        this.selectedUser = JSON.parse(JSON.stringify(User.user));
        if (this.selectedUser && this.selectedUser.allowedFacilities === null) {
          this.selectedUser.allowedFacilities = [];
        }
        this.selectedUser.customerNo = this.organization.customerNo;
        this.showEditUserDialog = true;
      },

      addUserDialog() {
        this.dialogMode = 'add';
        this.selectedUser = JSON.parse(JSON.stringify(User.user));
        this.selectedUser.customerNo = this.organization.customerNo;
        if (this.selectedUser && this.selectedUser.allowedFacilities === null) {
          this.selectedUser.allowedFacilities = [];
        }
        this.showEditUserDialog = true;
      },

      deleteUserDialog() {
        if (this.selected) {
          this.showDeleteUserDialog = true;
        }
      },

      unlockUserDialog() {
        if (this.selected) {
          this.showUnlockUserDialog = true;
        }
      },

      editUserDialog() {
        if (this.selected) {
          this.dialogMode = 'update';
          this.selectedUser = JSON.parse(JSON.stringify(this.selected));
          this.selectedUser.initialpw = '';
          if (this.selectedUser && this.selectedUser.allowedFacilities === null) {
            this.selectedUser.allowedFacilities = [];
          }
          this.showEditUserDialog = true;
        }
      },

      updateUserSuccess() {
        this.showEditUserDialog = false;
        this.lastUser = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;

        if (this.dialogMode === 'update') {
          this.$store.commit('successMsg', 'Die Änderungen für Benutzer <b>' + this.lastUser + '</b> wurden erfolgreich gespeichert!');
          this.success = true;
        }
        if (this.dialogMode === 'add') {
          this.$store.commit('successMsg', 'Der Benutzer <b>' + this.lastUser + '</b> wurde erfolgreich hinzugefügt.');
          this.success = true;
        }
        if (this.dialogMode === 'create') {
          this.$store.commit('successMsg', 'Der Benutzer <b>' + this.lastUser + '</b> wurde erfolgreich angelegt!');
          this.success = true;
        }

        this.success = true;
        this.selected = null;
        this.getAllUsers();
      },

      onSelectProfile() {
        if (this.selectedProfile !== '' && this.selectedProfile !== 'undefined') {
          this.searchOnTable();
        }
      },

      onSelectFacility() {
        if (this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          if (this.selectedFacilityReferenceNumber) {
            localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);
          }

          this.selectedFacilityName = '';
          for (let facility of this.availableFacilities) {
            if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacilityName = facility.name;
            }
          }

          this.searchOnTable();
        }
      },

      onDeleteUser() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          UserService.deleteUser(this.selected.id)
            .then(response => {
              this.sending = false;
              this.$store.commit('successMsg', 'Der Benutzer <b>' + this.selected.username + '</b> wurde erfolgreich gelöscht.');
              this.success = true;
              this.users = response.data;
              this.searchOnTable();
              this.search = null;
              this.selected = null;
              this.showDeleteUserDialog = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen von Benutzer ' + this.selected.username);
              this.sending = false;
            })
        }
      },

      onUnlockUser() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          UserService.unlockUser(this.selected)
            .then(response => {
              this.sending = false;
              this.$store.commit('successMsg', 'Der Benutzer <b>' + this.selected.username + '</b> wurde erfolgreich entsperrt!');
              this.success = true;
              this.search = null;
              this.selected = response.data;
              this.showUnlockUserDialog = false;
              this.reloadUsers();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen von Benutzer ' + this.selected.username);
              this.sending = false;
            })
        }
      },

      searchOnTable() {
        for (let user of this.users) {
          user.rolesProfileLabel = this.labels.rolesprofile[user.rolesProfile];
        }

        this.searched = searchByName(this.users, this.search);
        this.searched = searchByFacility(this.searched, this.selectedFacilityReferenceNumber);
        this.searched = searchByProfile(this.searched, this.selectedProfile);
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      onSelect(item) {
        if (item) {
          this.selected = JSON.parse(JSON.stringify(item));
        } else {
          this.selected = null;
        }
      },
      */
    },

    computed: {
      dataAvailable() {
        return (this.$store.getters.sage && (this.user && this.user.allowedFacilities && this.user.allowedFacilities.length > 0 && this.labels)) ||
          (!this.$store.getters.sage && (this.organization && this.organization.facilities && this.user && this.user.allowedFacilities && this.user.allowedFacilities.length > 0 && this.labels));
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      organization() {
        return this.$store.getters.organization;
      },

      user() {
        return this.$store.getters.user;
      },

      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang="scss" scoped>
  i {
    color: black;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }
</style>
